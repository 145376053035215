<div class="header-simple">
   <img src="../../assets/logo-wtz-hulk.png" alt="WatZap Hulk"/>
   <div class="logout-button">
      <a (click)="LogOut()" href="javascript:;">
         <i class="material-icons">exit_to_app</i>
      </a>
   </div>
 </div>
<div class="content-after-login whatsapp-list">
 
    <div class="child" *ngFor="let license of listLicenses">
        <div class="card auto-open-check" data-auto_open="no">
            <div *ngIf="license.is_connected == '0'" class="card-action red darken-4">
              <span class="card-title white-text"><i class="material-icons left">cloud_off</i> {{license.whatsapp_name}} ({{license.whatsapp_number}})</span>
            </div>
            <div *ngIf="license.is_connected == '1'" class="card-action gradient-45deg-green-teal">
               <span class="card-title white-text"><i class="material-icons left">cloud_done</i> {{license.whatsapp_name}} ({{license.whatsapp_number}})</span>
            </div>
            <div class="card-content">
               <table style="margin-top: -15px;margin-bottom: -20px;">
                  <tbody>
                     <tr style="display: none;">
                        <td>Nomor WhatsApp</td>
                        <td>{{license.whatsapp_number}}</td>
                     </tr>
                     <tr>
                        <td>Nama Internal</td>
                        <td>{{license.whatsapp_name}} <a href="javascript:;" class="edit_internal_name" (click)="ShowEditPopup(editinternalname, license)"><i class="material-icons">create</i></a></td>
                     </tr>
                     <tr *ngIf="license.is_connected == '0'">
                        <td>Status</td>
                        <td><span class="darken-4 red white-text left" style="padding: 3px 10px;border-radius: 5px;">Terputus</span></td>
                     </tr>
                     <tr *ngIf="license.is_connected == '1'">
                        <td>Status</td>
                        <td><span class="darken-2 green white-text left" style="padding: 3px 10px;border-radius: 5px;">Terhubung</span></td>
                     </tr>
                     <tr>
                        <td>Dihubungkan pada</td>
                        <td>{{license.latest_connect | date:'medium'}}</td>
                     </tr>
                     <tr>
                        <td>Lisensi</td>
                        <td>{{license.licenses_key}}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <div class="card-action m-text-center d-flex gap-3 justify-content-between">
               <a *ngIf="license.is_connected == '0'" (click)="loadQrCode(content, license)" class="show_modal_qr waves-effect waves-light btn darken-2 green m-lebar100"><i class="material-icons left">phonelink</i>Hubungkan Nomor</a>
               <a *ngIf="license.is_connected == '1'" class="waves-effect waves-light btn darken-4 red m-lebar100"
               mwlConfirmationPopover
               popoverTitle="Disconnect WhatsApp"
               popoverMessage="Are You Sure?"
               confirmText="Disconnect"
               cancelText="Cancel"
               placement="top"
               (confirm)="disconnectNomor(content, license)"
               (cancel)="cancelClicked = true"
               confirmButtonType="danger"
               cancelButtonType="outline-secondary"
               [appendToBody]="true"><i class="material-icons left">phonelink_off</i>Keluar</a>

               <div class="d-flex gap-2 align-items-center">
                  <label class="mr-2">Warmer Off</label>
                     <label class="switch">
                        <input type="checkbox" [(ngModel)]="warmersStatus[license.licenses_key]" (change)="toggle(license.licenses_key)">
                        <span class="slider round"></span>
                     </label>
                  <label class="ml-2">Warmer On</label>
               </div>
            </div>
        </div>
     </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Scan QR Code</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="area-qrcode">
           <div class="qrcodeexist" *ngIf="QrCodeURI != ''">
            <qrcode [qrdata]="QrCodeURI" [width]="256" [allowEmptyString]="true" [errorCorrectionLevel]="'M'"></qrcode>
           </div>
           <div class="qrcodenotexist frame-heart-dag-dig-dug" *ngIf="QrCodeURI == ''">
            <div class="lds-heart"><div></div></div>
           </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cancel</button>
    </div>
</ng-template>

<ng-template #editinternalname let-modal>
   <form class="FormChangeInternalName" (ngSubmit)="processSaveInternalName()" [formGroup]="FormChangeInternalName">
      <div class="modal-header">
         <h4 class="modal-title" id="modal-basic-title">Change Internal Name</h4>
         <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body">
         <div class="form=group">
            <label class="control-label">Internal Name</label>
            <input type="hidden" name="id" formControlName="id"/>
            <input type="text" name="name" formControlName="name" class="form-control name" placeholder="ex: Nomor Notif A" required="required"/>
         </div>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn light darken-2" (click)="modal.close()">Cancel</button>
         <input [disabled]="FormChangeInternalName.invalid" class="btn green darken-2" type="submit" name="commit" value="Save">
       </div>
   </form>
</ng-template>