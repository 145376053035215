import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { URIConf } from '../config/URIConf';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.css']
})
export class WhatsappComponent implements OnInit {

  toggleValue: boolean = false;
  warmersStatus:any = []

  listLicenses:any = [];
  isLoading: boolean = false;
  submitText: string = 'Save';
  unsorted() { }
  isLoaded:boolean = false;
  closeResult = '';
  closeResultEditContent = '';
  QrCodeURI:string = "";
  checkingNext:boolean = false;
  intervalChecking:any;
  FormChangeInternalName!: FormGroup;

  placements = ['top', 'left', 'right', 'bottom'];
  popoverTitle = 'Are you sure?';
  popoverMessage = 'Are you really <b>sure</b> you want to do this?';
  confirmText = 'Yes <i class="fas fa-check"></i>';
  cancelText = 'No <i class="fas fa-times"></i>';
  confirmClicked = false;
  cancelClicked = false;
  existRequestDisconnected:boolean = false;

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private httpClient: HttpClient, private toastr: ToastrService) { }

  processSaveInternalName(): void{
    var t = this;
    t.isLoading = true;
    t.submitText = 'Processing';

    this.modalService.dismissAll();

    const sending = {
      licenses_key: this.FormChangeInternalName.value.id,
      whatsapp_name: this.FormChangeInternalName.value.name,
      id: localStorage.getItem('id')
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/change_internal_name', sending).subscribe({
      next: data => {

        var state = data.state;
        var ack = data.ack;

        if(ack == 'sucessfully'){
          t.toastr.success(state, 'Save Internal Name', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });

          t.loadListLicenses();
        }else{
          t.toastr.error(state, 'Save Internal Name', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });
        }

      },
      error: error => {
        t.toastr.error(error, 'Save Internal Name', {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true
        });

        t.isLoading = false;
        t.submitText = 'Save';
      }
    });
  }

  ShowEditPopup(editinternalname: any, objLicense: any): void{
    var t = this;
    t.FormChangeInternalName.setValue({
      id: objLicense.licenses_key,
      name: objLicense.whatsapp_name
    });

    this.modalService.open(editinternalname, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResultEditContent = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResultEditContent = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  LogOut(): void{
    localStorage.removeItem("name");
    localStorage.removeItem("id");
    this.router.navigate(['/login']);
  }

  getQrCode(licenses_key: string): void{
    var t = this;
    const sending = {
      licenses_key: licenses_key,
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/state', sending).subscribe({
      next: data => {

        console.log('data', data);

        var dataResp = data.data;
        var ack = data.ack;
        var state = data.state;
        var type = data.type;

        if(ack == 'waiting_scan'){
          t.QrCodeURI = dataResp;
        }else if(ack == 'already_connected'){
          t.checkingNext = false;
          window.location.reload();
        }else{

        }
      },
      error: error => {
        
      }
    });
  }

  triggerDisconnection(licenses_key: string): void{
    var t = this;

    //Check State nya dulu
    const sendingState = {
      licenses_key: licenses_key,
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/state', sendingState).subscribe({
      next: data => {

        var dataResp = data.data;
        var ack = data.ack;

        if(ack == 'already_connected'){
          const sending = {
            licenses_key: licenses_key,
          };
          t.httpClient.post<any>(URIConf.baseUrl+'/disconnect', sending).subscribe({
            next: dataDc => {
      
              var ackDc = dataDc.ack;
              var stateDc = dataDc.state;
      
              if(ackDc == 'successfully'){
                window.location.reload();
              }else{
                t.toastr.error(stateDc, 'Disconnect License', {
                  positionClass: 'toast-bottom-right',
                  progressBar: true,
                  closeButton: true
                });
              }
            },
            error: error => {
              t.toastr.error(error, 'Disconnect License', {
                positionClass: 'toast-bottom-right',
                progressBar: true,
                closeButton: true
              });
            }
          });
        }else{
          t.toastr.error('Licenses Not Ready', 'Disconnect License', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });
        }
      },
      error: error => {
        t.toastr.error(error, 'Disconnect License', {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true
        });
      }
    });

   
  }

  triggerConnection(licenses_key: string): void{
    var t = this;
    const sending = {
      licenses_key: licenses_key,
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/initialize', sending).subscribe({
      next: data => {

        var ack = data.ack;
        var state = data.state;
        var type = data.type;

        if(ack == 'already'){

        }else if(ack == 'starting'){
          t.intervalChecking = setInterval(() => {
            if(t.checkingNext){
              t.getQrCode(licenses_key);
            }else{
              clearInterval(t.intervalChecking);
            }
          }, 1000);
        }else{
          t.toastr.error(state, 'Starting Engine', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });
        }
      },
      error: error => {
        t.toastr.error(error, 'Starting Engine', {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true
        });
      }
    });
  }

  disconnectNomor(content: any, objLicense: any): void{
    var t = this;

    var t = this;
    const sending = {
      licenses_key: objLicense.licenses_key,
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/state', sending).subscribe({
      next: data => {

        console.log('data', data);

        var dataResp = data.data;
        var ack = data.ack;
        var state = data.state;
        var type = data.type;

        if(ack == 'already_connected'){
          t.triggerDisconnection(objLicense.licenses_key);
        }else{

        }
      },
      error: error => {
        
      }
    });
    
  }

  loadQrCode(content: any, objLicense: any): void{

    var t = this;
    t.QrCodeURI = "";
    t.checkingNext = true;
    this.triggerConnection(objLicense.licenses_key);

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      clearInterval(t.intervalChecking);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      clearInterval(t.intervalChecking);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  disconnect(objLicense: any): void{

  }

  loadListLicenses(): void{
    var t = this;
    const sending = {
      id: localStorage.getItem("id"),
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/list_licenses', sending).subscribe({
      next: data => {

        var ack = data.ack;
        var dataResp = data.data;
        var state = data.state;
        var type = data.type;
        t.isLoaded = true;

        if(ack == 'successfully'){
          t.listLicenses = dataResp;

          t.listLicenses.map((value:any, key:any) => {
            // console.log('Vals', value)
            this.warmersStatus[value?.licenses_key] = value?.warmer_status;
          })

          // console.log('Arx', t.listLicenses)

          //warmersStatus
        }else{
          t.toastr.error(state, 'List Licenses', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });
        }
      },
      error: error => {
        t.toastr.error(error, 'List Licenses', {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true
        });

      }
    });
  }

  toggle(licenses_key: any) {
    // You can perform any actions here when the toggle switch changes
    console.log('Toggle value:', this.warmersStatus[licenses_key]);

    var t = this;
    const sending = {
      licenses_key: licenses_key,
      warmer_state: ((this.warmersStatus[licenses_key]) ? 1 : 0)
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/change_warmer_state', sending).subscribe({
      next: data => {

        var ack = data.ack;
        var state = data.state;
    
        if(ack == 'successfully'){
          t.toastr.success("Warmer State Update Successfully", "Warmer State")
        }else{
          t.toastr.error(state, 'List Licenses', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });
        }
      },
      error: error => {
        t.toastr.error(error, 'List Licenses', {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true
        });

      }
    });
  }

  ngOnInit(): void {
    if(localStorage.getItem("id") === null){
      this.router.navigate(['/login']);
    }else{
      this.loadListLicenses();

      this.FormChangeInternalName = this.formBuilder.group({
        name: [, Validators.required],
        id: [, Validators.required]
      });
    }
  }

}
