<div class="frame-login">
    <div class="content">
        <div class="login">
            <h1>WatZap Hulk API</h1>
            <form class="LoginFormGroup" (ngSubmit)="processLogin()" [formGroup]="LoginFormGroup">
                <p><input type="text" formControlName="email" name="email" value="" placeholder="Username or Email"></p>
                <p><input type="password" formControlName="password" name="password" value="" placeholder="Password"></p>
                <p class="submit"><input [disabled]="LoginFormGroup.invalid" type="submit" name="commit" value="Login"></p>
            </form>
        </div>
    </div>
</div>