import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { URIConf } from '../config/URIConf';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  LoginFormGroup!: FormGroup;
  isLoading: boolean = false;
  loginText = 'Login';

  constructor(private toastr: ToastrService, private router: Router, private formBuilder: FormBuilder, private httpClient: HttpClient) { }

  processLogin(): void{
    var t = this;
    t.isLoading = true;
    t.loginText = 'Processing';

    const sending = {
      email: this.LoginFormGroup.value.email,
      password: this.LoginFormGroup.value.password,
    };
    this.httpClient.post<any>(URIConf.baseUrl+'/login', sending).subscribe({
      next: data => {

        var ack = data.ack;
        var dataResp = data.data;
        var state = data.state;
        var type = data.type;

        if(ack == 'successfully'){
          t.toastr.success(state, 'Login', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });
          localStorage.setItem('name', dataResp.name);
          localStorage.setItem('id', dataResp.id);

          t.isLoading = false;
          t.loginText = 'Login';
          this.router.navigate(['/whatsapp']);

        }else{
          t.toastr.error(state, 'Login', {
            positionClass: 'toast-bottom-right',
            progressBar: true,
            closeButton: true
          });

          t.isLoading = false;
          t.loginText = 'Login';
          
        }
      },
      error: error => {
        t.toastr.error(error, 'Login', {
          positionClass: 'toast-bottom-right',
          progressBar: true,
          closeButton: true
        });

        t.isLoading = false;
        t.loginText = 'Login';
      }
    });
  }

  ngOnInit(): void {

    if(localStorage.getItem("id") === null){
      this.LoginFormGroup = this.formBuilder.group({
        email: [, Validators.required],
        password: [, Validators.required]
      });
    }else{
      this.router.navigate(['/whatsapp']);
    }

  }

}
